interface SectionProps {
  heading: string;
  preamble: string;
  image: {
    alt: string;
    src: string;
  };
}

const Section_12 = ({ heading, preamble, image }: SectionProps) => {
  return (
    <section className="bg-lab h-full flex justify-center items-center">
      <div className="max-w-5xl text-center px-7 py-14">
        <h4 className="font-schoolbell md:text-5xl text-3xl text-green leading-none">
          {heading}
        </h4>
        <p className="mt-3 font-miriam md:text-xl md:leading-loose leading-loose text-sm">
          {preamble.split("<br/>").join("\n")}
        </p>

        <img
          className="md:w-1/3 w-72 mx-auto mt-14"
          src={image.src}
          alt={image.alt}
        />
      </div>
    </section>
  );
};

export default Section_12;
