import Button from "../components/Button";
import Section_6_6 from "../components/Section_6_6";
import Section_12 from "../components/Section_12";
import { useEffect, useState } from "react";
import Card from "../components/Card";
import Accordion from "../components/Accordion";
import Footer from "../components/Footer";

const FAQ = [
  {
    question: "How many Dr Pickles World NFTs are there?",
    answer:
      "Max supply is 1200. 300 Apes, 300 Pepes, 300 Humans and 300 Pickles.",
  },
  {
    question: "1200 Supply? Four bases? Seems weird…",
    answer:
      "Yeah. It's weird. Each base has different characteristics and potential.",
  },
  {
    question: "Is the vaccine safe?",
    answer: "100%",
  },
  {
    question: "What can I do with a Dr Pickles World NFT?",
    answer:
      "Gain access to exclusive Dr Pickles World content. Be a decision maker in our satirical style of storytelling. Audition for paid voice acting roles. Flex your beautiful, priceless PFP on Twitter.",
  },
  ,
  {
    question: "When does minting start?",
    answer: "April 3rd, 15:15 UTC.",
  },
];

function Home() {
  /*
  const [playing, toggle] = useAudio("./assets/Dr._Pickles.mp3");

  const toggleAudio = ()=>{
    console.log("toggling")
    toggle();
  }*/

  useEffect(() => {
    document.onmousemove = (e) => {
      let x = (e.clientX * 100) / window.innerWidth + "%";
      let y = (e.clientY * 100) / window.innerHeight + "%";

      const FIRST_EYE = document.getElementById("first_eye_pupil");
      const SECOND_EYE = document.getElementById("second_eye_pupil");

      if (FIRST_EYE && SECOND_EYE) {
        FIRST_EYE.style.left = x;
        SECOND_EYE.style.left = x;

        FIRST_EYE.style.top = y;
        SECOND_EYE.style.top = y;

        FIRST_EYE.style.transform = "translate(-" + x + ",-" + y + ")";
        SECOND_EYE.style.transform = "translate(-" + x + ",-" + y + ")";
      }
    };
  }, []);

  return (
    <main className="bg-lab flex flex-col max-w-screen-2xl mx-auto overflow-hidden">
      <section className="flex justify-items-end pt-24">
        <img
          src="logo_dr.png"
          className="w-full h-auto mx-auto z-40 md:scale-100 scale-125"
        />
      </section>
      <div className="py-12 mx-auto">
        <Button text={"Mint Here!"} type={"link"} url="https://drpickles.world/mint" />
      </div>
      <Section_12
        heading="The Collection"
        preamble="Dr Pickles World is a collection of 1200 generated NFT test subjects that have volunteered to take his experimental vaccine for accidents. There are four subjects. Human, Ape, Pickle and Pepe. Naturally there are side effects. Some are weird. Some are just damn cool! These are provocative profile pics you can use for your online activities. 100% safe and effective!"
        image={{
          src: "./luck.svg",
          alt: "aids",
        }}
      />

      <Section_6_6
        heading="Promise & Disclaimer"
        preamble="Dr Pickles is confident it will go well this time. Early results have been slightly promising, but that could be an accident. Some patients have complained about sweating, hairiness, paranoia, uncontrollable drooling and unusual teeth activity. "
        imageAlignment="left"
        image={{
          isPickle: false,
          src: "./main_1_animation_512.gif",
        }}
      />

      <section className="bg-lab h-full flex justify-center items-center py-24">
        <div className="max-w-5xl w-full text-center px-7">
          <div className="font-schoolbell text-center text-green -mb-7">
            The Four Subjects
          </div>
          <div className="w-full flex flex-row justify-between flex-wrap">
            <Card
              image={{
                src: "./human.png",
              }}
              type={"Human"}
              bio={
                "Doing his bit to make the world a safer place, the human is prepared to ignore potential side effects."
              }
            />
            <Card
              image={{
                src: "./ape-base.png",
              }}
              type={"Ape"}
              bio={
                "Not being accident prone, the ape wonders why he's been included in the trial at all. That, and why his nipples need to be shaved to take part."
              }
            />
          </div>
          <div className="w-full flex flex-row justify-between flex-wrap">
            <Card
              image={{
                src: "./pepe-base.png",
              }}
              type={"Pepe"}
              bio={
                "Even megastar internet memes are not immune to unfortunate events. Pepe can't imagine what could possibly go wrong!"
              }
            />
            <Card
              image={{
                src: "./pickle-base.png",
              }}
              type={"Pickle"}
              bio={
                "Dr Pickles has a secret ingredient, extracted from the distilled juice of pickles. Immunity from accidents shouldn't be limited to humans, apes and internet memes!"
              }
            />
          </div>
        </div>
      </section>

      <Section_6_6
        heading="Mission Statement"
        preamble="When Dr Pickles discovered that 120% of accidents are avoidable, he was determined to make a difference. Even though initial trial results were promising (the data will be released in 100 years), Big Pharma wanted to corner the market for themselves. He decided to go ahead without them."
        imageAlignment="right"
        audio
        image={{
          isPickle: false,
          alt: "Letter from Dr Pickles",
          src: "./letter.png",
        }}
      />

      {/** 
      <Player url="/Dr._Pickles.mp3"/>
     */}
      {/* 
      <div className="md:mb-0 mt-7 mx-auto py-7 md:hidden block">
        <Button
          text={"Join our Discord"}
          type={"link"}
          url="https://discord.gg/zW7ThBvyKs"
        />
      </div> */}

      <section className="flex flex-col mx-auto w-full max-w-5xl py-24 px-12">
        <div className="font-schoolbell text-center text-green pb-7">FAQ</div>
        {FAQ.map((accordion: any, index: number) => {
          return (
            <Accordion
              key={index}
              question={accordion.question}
              answer={accordion.answer}
            />
          );
        })}
      </section>
      <Footer />
    </main>
  );
}

export default Home;
